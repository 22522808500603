import { IDSFCContent, IDSFCErrorMessage, IFileErrorMessage, IDSFCLinks, ISignInSignOut, IDSAContent, IController, IDSAControllerErrorMessage, IDataControllerTypeOptions, IDSAProcessorErrorMessage, IDSAErrorMessage, IDataSet, IDataSetErrorMessage, IFileTypeSize, IDataSetLevelTypeOptions, IDataSetFrequencyTypeOptions, IDataSetStandardisationServiceLevelOptionsOptions, IDataSetGroupTypeOptions, IParticipantErrorMessage, IParticipantTypeOptions, IDSASummary, IDSASummaryErrorMessage, IDataSetAPYFrequencyTypeOptions, IFundingSourceTypeOptions, IFundingErrorMessage, IApplicantorPartnerOptions, IPurposeErrorMessage,  ILegalBasesIdentifiableDataOptions, IEvidenceErrorMessage, ILocations, IProcessAndStorageLocationsErrorMessage, ISubmitApplicationErrorMessage, IApplicationChangeType, IPrivacyNoticeErrorMessage, IProcessAndStorageLocationsValue, IAmendRenewExtend, ISecurityRolesValues, IApproverRolesValues, IPageConfigList, IPageConfig, ILegalBasisValue, ISmallNumbersVisibleOrSupressed, IProductGroup, IObjectKeys, IFileUpload, ISectionList, IDataProcessorTypeOptions, IEvidenceTypeOptions, IYieldedBenefits_ErrorMessage } from "../state/models/app.interface"

export const pages = [
    { title: 'Home', icon: 'HomeIcon' },
    { title: 'DSFCIntroduction', icon: 'BellIcon' }
];

export const pageTitles = {
    home: "Data Access Request Service",
    applydsa: "Manage applications",
    activedsa: "Manage DSAs",
    dashboard: "Data Sharing Framework Contracts",
    help: "Information to help you",
    latestnews: "Updates",
    guidance: "Data Access Request Service",
    serviceError: "Service Error",
    serviceUnavailable: "Service Unavailable",
    contactUs: "Contact us",
    aboutUs: "About NHS England's Data Access Request Service (DARS)",
    signOut: "Signed out",
    dsfcDownload: "Data Sharing Framework Contract",
    accessibility: "Accessibilty statement - NHS website - NHS"
};

export const buttonText = {
    btnSaveAndContinue: "Save and continue",
    btnTextContinue: "Continue",
}

export const tables = [
    {
        name: 'new_datasharingframeworkcontract',
        columns: [
            {
                "name": "cts_applicationchangetype",
                "type": "options",
                "value": {
                    "new": "923180000",
                    "Extension": "923180001",
                    "Renewal": "923180002",
                    "Amendment": "923180003"
                }
            }
        ]
    }
]

export const dsfcContent: IDSFCContent = {
    btnTextStart: "Start Now",
    btnTextContinue: "Continue",
    btnTextNext: "Next",
    btnTextSaveClose: "Save and close",
    btnTextAcceptContract: "Accept contract",
    btnTextSubmit: "Submit",
    btnTextClose: "Close",
    btnSaveAndContinue: "Save and continue",
    txtLoading: "Loading",
    txtLoadingResult: "Loading Result",
    applyForDSFC: "Apply for a DSFC",
    newDSFCInProgress: "NewDSFCInProgress",
    renewalDSFCInProgress: "RenewalDSFCInProgress",
    no: "no",
    yes: "yes",
    false: "false",
    true: "true",
    next: "next",
    customerSignatory: "customerSignatory",
    close: "close",
    agreeContract: "agreeContract",
    saveAndClose: "saveAndClose",
    save: "save",
    isICO: "isICO",
    alternativeStreet1: "alternativeStreet1",
    alternativeCity: "alternativeCity",
    alternativeCounty: "alternativeCounty",
    alternativePostCode: "alternativePostCode",
    alternativeCountry: "alternativeCountry",
    alternativeOrganisationName: "alternativeOrganisationName",
    alternativeStreet2: "alternativeStreet2",
    alternativeStreet3: "alternativeStreet3",
    submit: "submit"
}

export const controllerDetails: IController = {
    btnSaveAndContinue: "Save and continue"
}

export const dataControllerTypeOptions: IDataControllerTypeOptions = {
    soleDataControllerValue: "923180000",
    jointDataControllerValue: "923180001",
    soleDataControllerText: "Sole",
    jointDataControllerText: "Joint",
    dsptSecurityAssuranceValue: "923180003",
    isoSecurityAssuranceValue: "923180001",
    slspSecurityAssuranceValue: "923180004",
    dsptSecurityAssuranceText: "DSPT",
    isoSecurityAssuranceText: "ISO",
    slspSecurityAssuranceText: "SLSP",
}

export const dataProcessorTypeOptions: IDataProcessorTypeOptions = {
    dsptSecurityAssuranceValue: "923180003",
    isoSecurityAssuranceValue: "923180001",
    slspSecurityAssuranceValue: "923180004",
    dsptSecurityAssuranceText: "DSPT",
    isoSecurityAssuranceText: "ISO",
    slspSecurityAssuranceText: "SLSP",
}

export const signInSignOut: ISignInSignOut = {
    signInText: "Sign in",
    signOutText: "Sign out"
}

export const dsaControllerErrorMessage: IDSAControllerErrorMessage = {
    selectController_ErrorMessage: "Select if the organisation is a sole or joint Controller",
    selectControllerLink_ErrorMessage: "Organisation Controller",
    selectControllerSummary_ErrorMessage: "There is a problem",
    selectControllerBody_ErrorMessage: "Select if the organisation is a sole or joint Controller",
    selectSectionComplete_ErrorMessage: "Select if this section is completed",
    selectSectionCompleteLinkController_ErrorMessage: "Select 'Yes' if you have added all of your Controllers",    
    selectSectionCompleteLinkProcessor_ErrorMessage: "Select 'Yes' if you have added all of your Processors",
    selectSectionCompleteBody_ErrorMessage: "Select if this section is completed",
    selectChangeSoleControllerBody_ErrorMessage: "Select yes if you would like to continue as a sole Controller",
    selectChangeJointControllerBody_ErrorMessage: "Select if you want to continue as joint Controllers",
    enterVersion_ErrorMessage: "Enter version",
    selectSecurityAssuranceLink_ErrorMessage: "Select the security assurance you want to provide for this Controller",
    selectSecurityAssurance_ErrorMessage: "Select the security assurance you want to provide for this Controller ",
    dsptOdsCode_ErrorMessage: "Enter ODS code",
    dsptStatus_ErrorMessage: "Select a status",
    dateMonthYearMissingDatePublished_ErrorMessage: "Enter the date published",
    dateMonthYearMissingDateExpired_ErrorMessage: "Enter the expiry date",
    dateMonthYearMissingDateReview_ErrorMessage: "Enter last review date",
    dateMonthMissingDatePublished_ErrorMessage: "The date entered must include day and month",
    dateYearMissingDatePublished_ErrorMessage: "The date entered must include day and year",
    monthYearMissingDatePublished_ErrorMessage: "The date entered must include month and year",
    dateMissingDatePublished_ErrorMessage: "The date entered must include a day",
    monthMissingDatePublished_ErrorMessage: "The date entered must include a month",
    yearMissingDatePublished_ErrorMessage: "The date entered must include a year",
    validDatePublished_ErrorMessage: "Enter a valid date",
    validDayPublished_ErrorMessage: "Enter a valid day",
    validMonthPublished_ErrorMessage: "Enter a valid month",
    validYearPublished_ErrorMessage: "Enter a valid year",
    futureDatePublished_ErrorMessage: "The date entered must be in the past",
    selectYesToProcessData_ErrorMessage: "Select yes if this Controller is going to be processing the data",
    summaryTitle_ErrorMessage: "There is a problem",
    alternativeOrganisationNameLink_ErrorMessage: "Enter organisation name",
    alternativeStreet1Link_ErrorMessage: "Enter address line 1",
    alternativeStreet2Link_ErrorMessage: "Enter address line 2",
    alternativeStreet3Link_ErrorMessage: "Enter address line 3",
    alternativeCityLink_ErrorMessage: "Enter town or city",
    alternativePostCodeLink_ErrorMessage: "Enter postcode",
    alternativeCountryLink_ErrorMessage: "Enter country ",
    onlyAlphabetAllowed_ErrorMessage: "Allowed characters: a to z, A to Z",
    onlyNumericAlphabetAllowed_ErrorMessage: "Allowed characters: a to z, A to Z, 0 to 9",
    addorgDetailsSummaryBody_ErrorMessage: "The following fields are not complete",
    organisationSearchMandatory_ErrorMessage: "Enter the name or location of the organisation that will be a Controller",
    organisationNotSelected_ErrorMessage: "Select the name of the organisation that will be a Controller. If your organisation does not appear, use 'I can't find my organisation' to add it",
    enterRegistrationNumber_ErrorMessage: "Enter Registration Number",
    enterOrganisationName_ErrorMessage: "Enter Organisation Name",
    enteredExpiryDate_ErrorMessage: "The year entered must be a date in the future",
    deleteController_ErrorMessage: "Select 'Yes' if you want to delete",
    addAnotherController_ErrorMessage: "Select 'Yes' if you want to add another organisation",
    emptySecondOrganisation_ErrorMessage: "Enter the name or location of the organisation that will be the second Controller",
    duplicateOrg_ErrorMessage: "Second Controller can not be the same at the first Controller",
    secondOrganisationNotSelected_ErrorMessage: "Select the name of the organisation that will be the second Controller. If your organisation does not appear, use 'I can't find my organisation' to add it ",
    selectChangeWhichIsSoleController_ErrorMessage: "Select which Controller you want to be the sole Controller",
    payForThisService_ErrorMessage: "Select which organisation is paying the invoice",
    customDateMonthYearMissingDateExpired_ErrorMessage: "Enter the date registration expires",
    customDateMonthYearMissing_ErrorMessage: "The date entered must be today's date, or a date in the past",
    applicantComments_ErrorMessage: "Enter a document description",
    dateMonthYearMissingInitialCertification_ErrorMessage: "Enter the certification date",
    validateFutureStartDate_ErrorMessage: "Your proposed agreement's  start date must be in the future but by no more than one year",
    UploadFile_ErrorMessage: "Upload a file"

}

export const dsfcErrorMessage: IDSFCErrorMessage = {
    summaryTitle_ErrorMessage: "There is a problem",
    companiesHouseICOLink_ErrorMessage: "Address of organisation",
    companiesHouseICO_ErrorMessage: "Select 'Yes' or 'No'. Select no if this is not the legally registered organisation",
    alternativeOrganisationNameLink_ErrorMessage: "Enter the name of the organisation",
    alternativeStreet1Link_ErrorMessage: "Enter address line 1",
    alternativeStreet1_ErrorMessage: "Enter address line 1",
    alternativeStreet2Link_ErrorMessage: "Enter address line 2",
    alternativeStreet3Link_ErrorMessage: "Enter address line 3",
    alternativeCityLink_ErrorMessage: "Enter town or city",
    alternativeCity_ErrorMessage: "Enter your town or city",
    alternativeCountyLink_ErrorMessage: "Enter county",
    alternativeCounty_ErrorMessage: "Enter county",
    alternativePostCodeLink_ErrorMessage: "Enter postcode",
    alternativePostCode_ErrorMessage: "Enter postcode",
    alternativeCountryLink_ErrorMessage: "Enter country ",
    alternativeCountry_ErrorMessage: "Enter country",
    signatorySummaryBody_ErrorMessage: "Select the person who will sign and agree the Data Sharing Framework Contract",
    signatory_ErrorMessage: "Select the person who will sign and agree the Data Sharing Framework Contract",
    signatorySummaryLink_ErrorMessage: "Select the person who will sign and agree the Data Sharing Framework Contract",
    dsfcConfirm: "Check the check box to confirm you agree with the statement",
    dsfcSummaryBody_ErrorMessage: "Check the box to confirm your Data Sharing Framework Contract application is true and complete",
    dsfcLink_ErrorMessage: "Contract confirmation",
    onlyAlphabetAllowed_ErrorMessage: "Allowed characters: a to z, A to Z",
    onlyNumericAlphabetAllowed_ErrorMessage: "Allowed characters: a to z, A to Z, 0 to 9",
    approveConfirm_ErrorMessage: "Confirm you agree with the statement",
    approveLink_ErrorMessage: "Check the box to agree your Data Sharing Framework Contract",
    approveSummaryBody_ErrorMessage: "You must confirm you agree with the statement",
    dsfcDetailsRegisteredOrganisation_ErrorMessage: "Select 'Yes' or 'No'. Select no if this is not the legally registered organisation with the ICO",
    dsfcDetailsSummaryBody_ErrorMessage: "Check the box to confirm the information within this application is true and complete",

}

export const fileErrorMessage: IFileErrorMessage = {
    fileSummaryTitle_ErrorMessage: "There is a problem.",
    fileRequiredSummaryBody_ErrorMessage: "Select a file",
    fileValidSummaryBody_ErrorMessage: "Select a correct file",
    fileCorrect_ErrorMessage: "Only pdf, word and excel files are allowed",
    fileCorrectPDF_ErrorMessage: "Only pdf files are allowed",
    fileLink_ErrorMessage: "Select a file",
    fileSize_ErrorMessage: "File size must be less than 3 MB",
    fileUploded_ErrorMessage: "Upload the selected file",
    fileLength_ErrorMessage: "Only 6 files can be uploaded or changed once",
    fileRequiredToUpload_ErrorMessage: "Select a file to upload",
    fileSize_EmptyErrorMessage: 'The document you have uploaded is an empty file, upload another document.',
    fileAlreadyExists_ErrorMessage: "File already exists with this name. Please rename and try again."
}

export const dsfcLinks: IDSFCLinks = {
    dsfcTemplate: "https://digital.nhs.uk/binaries/content/assets/website-assets/services/dars/data-sharing-framework-contract"
}

export const dsaContent: IDSAContent = {
    dataProviderConfirmEmail: "dataProviderConfirmEmail",
    hint_dataProviderConfirmEmail:"Confirm email address of Participant Data Provider.",
    lbl_dataProviderConfirmEmail:"Confirm email address",
    selectedEvidenceUpload:"SelectedEvidenceUpload",
    btnNewApplication: "New application",
    btnTextStart: "Start Now",
    btnTextContinue: "Continue",
    btnTextClose: "Close",
    btnSaveAndContinue: "Save and continue",
    txtLoading: "Loading",
    txtLoadingResult: "Loading Result",
    dateInputDayField: "DSPTDay",
    dateInputMonthField: "DSPTMonth",
    dateInputYearField: "DSPTYear",
    dateInputDayMonthField: "DSPTDay,DSPTMonth",
    dateInputMonthYearField: "DSPTMonth,DSPTYear",
    dateInputDayYearField: "DSPTDay, DSPTYear",
    dateInputAllFields: "DSPTDay,DSPTMonth,DSPTYear",
    dsptStatusField: "DSPT_Latest_Status",
    dsptOdsCodeField: "DSPT_ODS_Code",
    controllerProcessingTheData: "yes",
    controllerNotProcessingTheData: "no",
    version: "ISOVersion",
    file: "file",
    upload: "upload",
    alternativeStreet1: "alternativeStreet1",
    alternativeStreet2: "alternativeStreet2",
    alternativeStreet3: "alternativeStreet3",
    alternativeCity: "alternativeCity",
    alternativeCounty: "alternativeCounty",
    alternativePostCode: "alternativePostCode",
    alternativeCountry: "alternativeCountry",
    alternativeOrganisationName: "alternativeOrganisationName",
    lbl_organisationName: "Organisation Name",
    lbl_addressLine1: "Address Line 1",
    lbl_addressLine2: "Address Line 2 (optional)",
    lbl_addressLine3: "Address Line 3 (optional)",
    lbl_townorCity: "Town or City",
    lbl_postcode: "Postcode",
    lbl_county: "County (optional)",
    lbl_country: "Country",
    lbl_orgSearch: "Search by name",
    refNumber: "Reference Number",
    totalestimatedCost: "Total Estimated Cost",
    goback: "Go Back",
    registrationNumberText: "dpaRegistrationNumber",
    organisationNameText: "dpaOrganisationName",
    targetIsProcessingDataText: "isProcessingData",
    summaryPageText: "Summary",
    yes: "Yes",
    no: "No",
    checkYourAnswersPageText: "CheckYourAnswers",
    addAnotheControllerLink: "Add another Controller",
    slspDay: "SLSPDay",
    slspMonth: "SLSPMonth",
    slspYear: "SLSPYear",
    applicantComments: "applicantComments",
    processorProcessingTheData: "yes",
    processorNotProcessingTheData: "no",
    addAnotherProcessorLink: "Add another Processor",
    isoDay: "ISODay",
    isoMonth: "ISOMonth",
    isoYear: "ISOYear",
    dpInputDayField: "dpDay",
    dpInputMonthField: "dpMonth",
    dpInputYearField: "dpYear",
    selectDuration: "selectDuration",
    monthlyFrequency: "Monthly",
    quarterlyFrequency: "Quarterly",
    annuallyFrequency: "Annually",
    dataDissemination: "dataDissemination",
    dataDisseminationLabel : "Data Dissemination",
    aggregatedOrRecordLevelData: "aggregatedOrRecordLevelData",
    linkedToCohort: "linkedToCohort",
    recordlevel: "Record level",
    aggregatedlevel: "Aggregated (without small number suppressed)",
    datasetFrequency: "datasetFrequency",
    acceptAgreementText: "Accept Agreement",
    blankText: "-",
    participantTypes: "participantTypes",
    fundingtype: "fundingType",
    dataProviderFirstName: "dataProviderFirstName",
    dataProviderLastName: "dataProviderLastName",
    dataProviderRole: "dataProviderRole",
    dataProviderOrganisationName: "dataProviderOrganisationName",
    dataProviderOrganisationNameNotListed:"dataProviderOrganisationNameNotListed",
    dataProviderEmail: "dataProviderEmail",
    lbl_dataProviderFirstName: "First name",
    lbl_dataProviderLastName: "Last name",
    lbl_dataProviderRole: "Role",
    lbl_dataProviderOrganisationName: "Organisation",
    lbl_dataProviderEmail: "Email",
    hint_dataProviderRole: "Role or Job Title of the Participant Data Provider",
    hint_dataProviderOrganisationName: "Search by name",
    hint_dataProviderEmail: "Email address of Participant Data Provider.",
    participantNewAgreement: "New",
    participanExistingtAgreement: "Existing agreement",
    fundingPublic: "Funding will be provided by a public sector organisation",
    fundingPrivate: "Funding will be provided by a private sector organisation",
    lbl_fundingAwardingInstruction: "Funding awarding institution",
    lbl_referenceTitle: "Reference and title of project",
    lbl_yearOfSubmissionDay: "Day",
    lbl_yearOfSubmissionMonth: "Month",
    lbl_yearOfSubmissionYear: "Year",
    lbl_yearOfSubmission: "Date of submission or award",
    applicant: "Applicant",
    partner: "Partner",
    hint_yearOfSubmission: "For example, 25 09 2023",
    yearOfSubmissionDay: "yearOfSubmissionDay",
    yearOfSubmissionMonth: "yearOfSubmissionMonth",
    yearOfSubmissionYear: "yearOfSubmissionYear",
    referenceTitle: "referenceTitle",
    fundingAwardingInstruction: "fundingAwardingInstruction",
    yearofSubmissionDateComplete: "yearofSubmissionDateComplete",
    participantDatatype: "participantData",
    evidenceApproval: "isEvidenceApproval",
    ethicsApproval: "isEthicsApproval",
    noEthicsApproval: "noEthicsApproval",
    dataSetSelectedLegalBasesOtherText: "dataSetSelectedLegalBasesOtherText",
    otherText: "other",
    privacyNotice: "privacyNotice",
    noPrivacyNotice: "noPrivacyNotice",
    btnTextSubmit: "Submit",
    extend: "Extend",
    organisationsInvolved: "organisationsInvolved",
    applyingForData: "applyingForData",
    dataAndParticipant: "dataAndParticipant",
    exampleYearForFuture: 2,
    exampleYearForPast: -40,
    dataSetSelectedLegalBasisOtherText: "dataSetSelectedLegalBasisOtherText",
    activeDsa: "active-dsa",
    noFunding: "Funding will only be provided by the Controllers",
    exampleYearForStartDate: 1,
    nhsNumber: "nhsNumber",
    dateOfBirth: "dateOfBirth",
    forename: "forename",
    sex: "sex",
    othergivenName: "otherGivenName",
    postCode: "postcode",
    dateOFDeath: "dateOfDeath",
    addressDate: "addressDate",
    telephoneNumber: "telephoneNumber",
    isFromExtentorRenew: "fromRenewExtend",
    isFromAmend: "fromAmend",
    applyDsa: "apply-dsa",
    manageDsaIntro: "manage-dsa-intro",
    managedsa :"manage-dsa",
    dsaIntro: "dsa-intro",
    amendIntro: "amend-intro",
    applyForDSA: "Apply for a DSA",
    approver: "approver",
    eventType: "eventType",
    previousPageUrl: "previousPageUrl",
    txtBack: "back",
    txtSubmit: "submit",
    exampleYearForCurrent: 0,
    exampleMonthForPast: -1,
    submitApplicationLink: "enableLink",
    submitApplicationStatus: "enableStatus",
    noPermission: "nopermission",
    saveAndContinue: "saveAndContinue",
    eventActionAdd: "add",
    eventActionRemove: "remove",
    invalidStatus: "invalidStatus",
    invalidRequest: "invalidRequest",
    reject: "reject",
    controller: "controller",
    saList: "saList",
    requestId: "6d5a1ea9-cecc-4dd6-9fe3-e0977d9fb3cf",
    serviceMaintenance: "ServiceMaintenance",
    defaultDebouncedInterval: 200,
    dsaFilenameReplaceSummayFile: "DSA_filenameReplace_summary.pdf",
    disabled: "disabled",
    legalbasesidentifiabledata: "legal-bases-identifiable-data",
    legalbasis: "legal-basis",
    tasklist: "task-list",
    exampleOneYearForPast: -1,
    selectSectionCompleteLink_RenewErrorMessage: "Select 'Yes' if you have completed this section.",
    selectedDataSet: "Data set selected",    
    controllerDetailsHeaderCheckYourAnswersPage: "Check your answers",
    controllerDetailsHeaderSummaryPage: "Check this controllers details",    
    isThisSectionComplete: "Is this section complete?",
    haveYouAddedAllProcessors: "Have you added all your Processors?",
    haveYouAddedAllDataset: "Have you added all relevant data sets?",
    yesIHaveAddedAllDataset: "Yes, I've added all relevant data sets",
    yesIHaveAddedAllprocessors: "Yes, I've added all my Processors",
    noIWillAddThemLater: "No, I'll add them later",
    noIWillComeBackLater: "No, I'll come back later",
    yesIHaveCompletedSection: "Yes, I've completed this section",
    haveYouCompletedController: "Have you completed the 'About the Controller' section?",
    noIWillBeBackLater: "No, I'll come back to it later",
    controllerDetails: "controller-details",
    processorDetails: "processor-details",
    solelyProcessor: "solely-processor",
    participantTypesPage: "participant-types",
    exisitngTypesPage: "existing-type",
    addAnotherDatasetRecord: "add-another-dataset-record",
    lbl_POnumber:"PO number",
    lbl_POnumberNotRequired: "PO number not required",
    poNumber: "poNumber",
    requiredPONumber: "isRequiredPOnumber",
    gobackEthicsApproval:"ethics-approval",
    gobacklegalbasis:"legal-basis",
    gobackidentifiabledata:"legal-bases-identifiable-data",
    gobackevidenceapproval:"evidence-approval"
}
export const applicationStatus: any = {
    "1001": "IN PROGRESS",
    "1002": "SUBMITTED",
    "1003": "REVIEW",
    "1004": "ACTION&nbsp;NEEDED",
    "1005": "IGARD REVIEW",
    "1006": "FINAL CHECKS",
    "1007": "READY TO SIGN",
    "1008": "ACTIVE AGREEMENT",
    "1009": "AGREEMENT EXPIRING",
    "1010": "AGREEMENT EXPIRED",
    "1011": "AWAITING SIGNATURE",
    "1012": "SIGNED BY YOU",
    "1013": "CLOSURE IN PROGRESS",
    "1014": "NOT STARTED",
    "1015": "COMPLETED",
    "1016": "CANNOT START YET",
    "1017": "MAKE CHANGES"
}

export const contractStatus: any = {
    "1001": "IN PROGRESS",
    "1002": "SUBMITTED",
    "1003": "FINAL CHECKS",
    "1004": "READY TO SIGN",
    "1005": "ACTIVE CONTRACT",
    "1006": "ACTION NEEDED",
    "1007": "CONTRACT EXPIRED",
    "1008": "AWAITING SIGNATURE",
}

export const controllerSection: any = {
    aboutController: 557340000,
    aboutProcessor: 557340001,
    aboutData: 557340011,
    Participants: 557340010,
    ProcessingLocations: 557340002,
    StorageLocations: 557340003,
    PrivacyNotice: 557340004,
    ProjectTitleReason: 557340006,
    CommercialPurposesAndSublicensing: 557340009,
    ObjectiveAndProcessingActivities: 557340007,
    ExpectedOutputsAndBenefits: 557340008,
    EthicsApproval: 557340012,
    Funding: 557340005,
    SubmitApplication: 557340014,
    ListOfUploadedDocuments: 557340013,
    YieldedBenefits: 557340015
}

export const sectionOptions: any = {
    DataController: "557340000",
    DataProcessor: "557340001",
    AboutTheData: "557340011",
    ProjectSummary: "557340015",
    Participants: "557340010",
    ProcessingLocations: "557340002",
    StorageLocations: "557340003",
    PrivacyNotice: "557340004",
    Funding: "557340005",
    ProjectTitleReason: "557340006",
    ObjectiveAndProcessingActivities: "557340007",
    ExpectedOutputsAndBenefits: "557340008",
    CommercialPurposesAndSublicensing: "557340009",
    ListOfUploadedDocuments: "557340013",
    EthicsApproval: "557340012",
    SubmitApplication: "557340014",
    YieldedBenefits: "557340015"
}
export const sectionOptionsDesc: any = {
    "About the Controller": "557340000",
    "About the Processor": "557340001",
    "About The data": "557340011",
    "Project Summary": "557340015",
    "About the participants": "557340010",
    "Processing Locations": "557340002",
    "Storage Locations": "557340003",
    "Privacy Notice": "557340004",
    "Funding": "557340005",
    "Data request reason and project name": "557340006",
    "Objective(s) And processing activities": "557340007",
    "Expected outputs And benefits": "557340008",
    "Commercial purposes And sub-licensing": "557340009",
    "List of uploaded documents": "557340013",
    "Ethics approval": "557340012",
    "Submit application": "557340014",
    "Yielded Benefits": "557340015"
}



export const taskListSectionStatus: any = {
    notStarted: "1001",
    inProgress: "1002",
    completed: "1003",
    cannotStartYet: "1004",
    makeChanges: "1005",
    actionNeeded: "1006"
}

export const controllerType: any = {
    923180000: "Sole Controller",
    923180001: "Joint Controller"
}

export const saType: any = {
    923180003: "DSPT",
    923180001: "ISO",
    923180004: "SLSP"
}

export const dsptStatus: any = {
    923180000: "Baseline",
    923180001: "Not Published",
    923180002: "Standards Not Met",
    923180003: "Standards Not Fully Met (Plan Agreed)",
    923180004: "Standards Met",
    923180005: "Standards Exceeded"
}
export const dsaProcessorErrorMessage: IDSAProcessorErrorMessage = {
    selectProcessor_ErrorMessage: "Select if the organisation is a sole or joint Processor",
    selectProcessorLink_ErrorMessage: "Organisation Processor",
    selectProcessorSummary_ErrorMessage: "There is a problem",
    selectProcessorBody_ErrorMessage: "Select if the organisation is a sole or joint Processor",
    selectChangeSoleProcessorBody_ErrorMessage: "Select 'yes' if you would like to continue as a sole Processor",
    selectChangeJointProcessorBody_ErrorMessage: "Select if you want to continue as joint Processor",
    selectSecurityAssurance_ErrorMessage: "Select the security assurance you want to provide for this Processor ",
    selectSecurityAssuranceLinkProcessor_ErrorMessage: "Select the security assurance you want to provide for this Processor ",
    selectYesToProcessData_ErrorMessage: "Select 'Yes' if this Processor is going to be processing the data",
    organisationSearchMandatory_ErrorMessage: "Enter the name or location of the organisation that will be a Processor",
    organisationNotSelected_ErrorMessage: "Select the name of the organisation that will be a Processor. If your organisation does not appear, use 'I can't find my organisation' to add it",
    emptySecondOrganisation_ErrorMessage: "Enter the name or location of the organisation that will be the second Processor",
    duplicateOrg_ErrorMessage: "Second Processor can not be the same as the first Processor",
    secondOrganisationNotSelected_ErrorMessage: "Select the name of the organisation that will be the second Processor. If your organisation does not appear, use 'I can't find my organisation' to add it ",
    selectChangeWhichIsSoleProcessor_ErrorMessage: "Select which Processor you want to be the sole Processor",
    applicantComments_ErrorMessage: "Enter a document description",
    enterVersion_ErrorMessage: "Enter version",
    UploadFile_ErrorMessage: "Upload a file"
}


export const dsaErrorMessage: IDSAErrorMessage = {
    selectSummary_ErrorMessage: "There is a problem",
    selectSectionCompleteBody_ErrorMessage: "Select if this section is completed",
    enterVersion_ErrorMessage: "Enter version",
    dsptOdsCode_ErrorMessage: "Enter ODS code",
    dsptStatus_ErrorMessage: "Select a status",
    dateMonthYearMissingDatePublished_ErrorMessage: "Enter the date published",
    dateMonthYearMissingDateExpired_ErrorMessage: "Enter the expiry date",
    dateMonthMissingDatePublished_ErrorMessage: "The date entered must include day and month",
    dateYearMissingDatePublished_ErrorMessage: "The date entered must include day and year",
    monthYearMissingDatePublished_ErrorMessage: "The date entered must include month and year",
    dateMissingDatePublished_ErrorMessage: "The date entered must include a day",
    monthMissingDatePublished_ErrorMessage: "The date entered must include a month",
    yearMissingDatePublished_ErrorMessage: "The date entered must include a year",
    validDatePublished_ErrorMessage: "Enter a valid date",
    validDayPublished_ErrorMessage: "Enter a valid day",
    validMonthPublished_ErrorMessage: "Enter a valid month",
    validYearPublished_ErrorMessage: "Enter a valid year",
    futureDatePublished_ErrorMessage: "The date entered must be in the past",
    summaryTitle_ErrorMessage: "There is a problem",
    alternativeOrganisationNameLink_ErrorMessage: "Enter organisation name",
    alternativeStreet1Link_ErrorMessage: "Enter address line 1",
    alternativeStreet2Link_ErrorMessage: "Enter address line 2",
    alternativeStreet3Link_ErrorMessage: "Enter address line 3",
    alternativeCityLink_ErrorMessage: "Enter town or city",
    alternativePostCodeLink_ErrorMessage: "Enter postcode",
    alternativeCountryLink_ErrorMessage: "Enter country ",
    onlyAlphabetAllowed_ErrorMessage: "Allowed characters: a to z, A to Z",
    onlyNumericAlphabetAllowed_ErrorMessage: "Allowed characters: a to z, A to Z, 0 to 9",
    addorgDetailsSummaryBody_ErrorMessage: "The following fields are not complete",
    enterRegistrationNumber_ErrorMessage: "Enter Registration Number",
    enterOrganisationName_ErrorMessage: "Enter Organisation Name",
    enteredExpiryDate_ErrorMessage: "The year entered must be a date in the future",
    addAnotherOrg_ErrorMessage: "Select 'Yes' if you want to add another organisation",
    delete_ErrorMessage: "Select 'Yes' if you want to delete",
    enterTaskDuration_ErrorMessage: "Select duration to populate end date",
    dsaApprovalConfirm_ErrorMessage: "Check the box to accept the terms and conditions",
    chooseSection_ErrorMessage: "Select a section",
    manageDsa_ErrorMessage: "Select one of the below actions",
    validEmail_ErrorMessage: "Enter a valid Email address",
    validConfirmEmail_ErrorMessage: "Enter a valid Confirm Email address"
}
export const anonymousPages = ["help", "guidance", "latestnews", "newsdetails", 'serviceerror']

export const newsPages = ["latestnews", "newsdetails"]

export const securityRolesValues: ISecurityRolesValues = {
    dsfcApproverSecurity: 923180002,
    dsaApproverSecurity: 923180001,
    dsfcApplicantSecurity: 923180005,
    dsaApplicantSecurity: 923180000
}

export const approverRolesValues: IApproverRolesValues = {
    dsfcApprover: 923180002,
    dsaApprover: 923180000,
    dsfcApplicantApprover: 923180003
}

export const entity = {
    "validSave": [{
        "controller": {
            "maxCount": 1,
            "minCount": 1
        }
    },
    {
        "processor": {
            "maxCount": 1
        }
    },
    {
        "controllerDetails": {
            "maxCount": 2,
        }
    }
    ]
}
const taskListCondition = [
    { condition: 'amend', pageName: 'dsa/task-list' },
    { condition: 'renew-extend', pageName: 'dsa/task-list' },
    { condition: 'task-list', pageName: 'dsa/task-list' },
]

//condition key is an identifier which is denoting the page route for go back
export const goBackLink = [
    {
        currentPage: 'controller-details',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'data-duration',
        previousPage:
            [
                { condition: 'dataset-check-your-answer', pageName:'dsa/dataset-check-your-answer'},
            ]
    },
    {
        currentPage: 'add-another-controller',
        previousPage:
            [
                { condition: 'controller-details', pageName: 'dsa/controller-details' }
            ]
    },
    {
        currentPage: 'controller-type',
        previousPage: [
            ...taskListCondition,
            { condition: 'controller-details', pageName: 'dsa/controller-details' }
        ]
    },
    {
        currentPage: 'processor-details',
        previousPage: [
            ...taskListCondition
        ]
    },
    {
        currentPage: 'solely-processor',
        previousPage: [
            ...taskListCondition
        ]
    },
    {
        currentPage: 'task-list',
        previousPage: [
            { condition: 'dsa-intro', pageName: 'dsa/dsa-before-you-start' },
            { condition: 'apply-dsa', pageName: 'dsa/apply-dsa' },
            { condition: 'choose-section', pageName: 'dsa/choose-section' },
        ]
    },
    {
        currentPage: 'evidence-type',
        previousPage: [
            { condition: 'add-another-approval-evidence', pageName: 'dsa/add-another-approval-evidence' },
            { condition: 'task-list', pageName: 'dsa/task-list' },
            { condition: 'upload-supporting-evidence', pageName: 'dsa/upload-supporting-evidence' }, 
        ]
    },
    {
        currentPage: 'add-another-approval-evidence',
        previousPage: [
            { condition: 'evidence-type', pageName: 'dsa/evidence-type' },
            { condition: 'task-list', pageName: 'dsa/task-list' },
        ]
    },
    {
        currentPage: 'uploaded-document-details',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'objective-processing-check-answer',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'expected-check-your-answers',
        previousPage:
            [
                ...taskListCondition,

            ]
    },
    {
        currentPage: 'summary-commercial',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'add-cohort-contributor',
        previousPage: [
            { condition: 'linking-participant', pageName: 'dsa/linking-participant' }
        ]
    },
    {
        currentPage: 'participant-summary',
        previousPage:
            [
                ...taskListCondition,
                { condition: 'add-cohort-contributor', pageName: 'dsa/add-cohort-contributor'},
                { condition: 'linking-participant', pageName: 'dsa/linking-participant' },
                { condition: 'legal-basis', pageName: 'dsa/legal-basis' }
            ]
    },
    {
        currentPage: 'dataset-details',
        previousPage:
            [
                ...taskListCondition, 
            ]
    },
    {
        currentPage: 'dataset-check-your-answer',
        previousPage:
            [
                { condition: 'dataset-details', pageName: 'dsa/dataset-details' },
            ]
    },
    {
        currentPage: 'aggregated-record',
        previousPage:
            [
                { condition: 'dataset-check-your-answer', pageName: 'dsa/dataset-check-your-answer' },
            ]
    },
    {
        currentPage: 'controller-data',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'processor-data',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'renew_extend-task-list',
        previousPage: [
            { condition: 'manage-dsa-intro', pageName: 'dsa/manage-before-you-start' },
            { condition: 'apply-dsa', pageName: 'dsa/apply-dsa' }
        ]
    },
    {
        currentPage: 'amend-task-list',
        previousPage: [
            { condition: 'amend-dsa-intro', pageName: 'dsa/amend-before-you-start' },
            { condition: 'choose-section', pageName: 'dsa/choose-section' }
        ]
    },
    {
        currentPage: 'no-existing-agreement',
        previousPage: [
            { condition: 'participant-data', pageName: 'dsa/participant-data' }
        ]
    },
    {
        currentPage: 'uploaded-files',
        previousPage: [
            { condition: 'legal-basis', pageName: 'dsa/legal-basis' },
            { condition: 'legal-bases-identifiable-data', pageName: 'dsa/legal-bases-identifiable-data' },
            ...taskListCondition,
        ]
    },
    {
        currentPage: 'who-will-provide',
        previousPage: [
            { condition: 'linking-participant', pageName: 'dsa/linking-participant' }
        ]
    },
    {
        currentPage: 'choose-section',
        previousPage: [
            { condition: 'amend-intro', pageName: 'dsa/amend-before-you-start' },
            { condition: 'apply-dsa', pageName: 'dsa/apply-dsa' },
            { condition: 'task-list', pageName: 'dsa/task-list' }

        ]
    },
    {
        currentPage: 'funding-type',
        previousPage:
            [
                ...taskListCondition,
                { condition: 'uploaded-document-details', pageName: 'dsa/uploaded-document-details' }
            ]
    },
    {
        currentPage: 'dataset-introduction',
        previousPage:
            [
                ...taskListCondition,
            ]
    },
    {
        currentPage: 'manage-before-you-start',
        previousPage: [
            { condition: 'manage-dsa', pageName: 'dsa/manage-dsa' },
            { condition: 'apply-dsa', pageName: 'dsa/apply-dsa' },
            { condition: 'task-list', pageName: 'dsa/task-list' }

        ]
    },
    {
        currentPage: 'amend-before-you-start',
        previousPage: [
            { condition: 'manage-dsa', pageName: 'dsa/manage-dsa' },
            { condition: 'apply-dsa', pageName: 'dsa/apply-dsa' },
            { condition: 'task-list', pageName: 'dsa/task-list' }

        ]
    },
    {
        currentPage: "legal-bases-identifiable-data",
        previousPage: [
            { condition: 'approval-evidence-data', pageName: 'dsa/approval-evidence-data' },
            { condition: 'uploadConsent', pageName: 'dsa/upload-consent' }
        ]
    },
    {
        currentPage: "approval-evidence-data",
        previousPage: [
            { condition: 'add-another-dataset-record', pageName: 'dsa/add-another-dataset-record'},
            { condition: 'uploadConsent', pageName: 'dsa/upload-consent' }
        ]
    },
    {
        currentPage: "upload-consent",
        previousPage: [
            { condition: 'add-another-dataset-record', pageName: 'dsa/add-another-dataset-record' },
            { condition: 'legal-bases-identifiable-data', pageName: 'dsa/legal-bases-identifiable-data' },
            { condition: 'legal-basis', pageName: 'dsa/legal-basis' },
            { condition: 'ethics-approval', pageName: 'dsa/ethics-approval' }
        ]
    },
    {
        currentPage: "ethics-approval",
        previousPage: [
            { condition: 'upload-consent', pageName: 'dsa/upload-consent' },
            { condition: 'add-another-ethics-review', pageName: 'dsa/add-another-ethics-review' },
            { condition: 'evidence-approval', pageName: 'dsa/evidence-approval' }
        ]
    },
    {
        currentPage: "evidence-approval",
        previousPage: [
            ...taskListCondition,
        ]
    },
    {
        currentPage: "add-another-dataset-record",
        previousPage: [
            { condition: 'added-all-dataset', pageName: 'dsa/added-all-dataset' },
            { condition: 'uploadConsent', pageName: 'dsa/upload-consent' },
            { condition: 'participant-summary', pageName: 'dsa/participant-summary' }

        ]
    },
{
        currentPage: "add-another-ethics-review",
        previousPage: [
            ...taskListCondition,
            { condition: 'ethics-approval', pageName: 'dsa/ethics-approval' }
        ]
    },
    {
        currentPage: "add-another-funding",
        previousPage: [
            ...taskListCondition,
        ]
    },
    {
        currentPage: 'manage-dsa',
        previousPage: [
            { condition: 'active-dsa', pageName: 'dsa/active-dsa' },

        ]
    }, 
    {
        currentPage: 'upload-supporting-evidence',
        previousPage: [
            ...taskListCondition,

        ]
    },
    {
        currentPage: 'pay-for-this-service',
        previousPage:
            [
                { condition: 'controller-details', pageName: 'dsa/controller-details' }
            ]
    },
    {
        currentPage: 'legal-basis',
        previousPage:
            [
                { condition: 'existing-type', pageName: 'dsa/existing-type' },
                { condition: 'participant-types', pageName: 'dsa/participant-types' }
            ]
    },
    {
        currentPage: 'linking-participant',
        previousPage:
            [
                { condition: 'add-another-dataset-record', pageName: 'dsa/add-another-dataset-record' },
                { condition: 'legal-basis', pageName: 'dsa/legal-basis' },
                { condition: 'participant-summary', pageName: 'dsa/participant-summary' }
            ]
    },
    {
        currentPage: 'slsp',
        previousPage:
            [
                { condition: 'add-another-slsp', pageName: 'dsa/add-another-slsp' },
            ]
    },
    {
        currentPage: 'add-another-slsp',
        previousPage:
            [
                { condition: 'controller-details', pageName: 'dsa/controller-details' }
            ]
    },
    {
        currentPage: 'processor-slsp',
        previousPage:
            [
                { condition: 'add-another-processorslsp', pageName: 'dsa/add-another-processorslsp' },
            ]
    },
    {
        currentPage: 'add-another-processorslsp',
        previousPage:
            [
                { condition: 'processor-details', pageName: 'dsa/processor-details' }
            ]
    }
]
  
export const dataSetErrorMessage: IDataSetErrorMessage = {
    selectSummary_ErrorMessage: "There is a problem ",
    datasetLevelmandatory_ErrorMessage: "Select aggregated or record level data",
    datasetCohortmandatory_ErrorMessage: "Select 'Yes' if you want to link this data set to a participant group you are providing",
    datasetFrequencytmandatory_ErrorMessage: "Select how frequently you would like to receive data ",
    selectDataSetSectionCompleteBody_ErrorMessage: "Select 'Yes' or 'No' to 'Have you added all relevant data sets?'",
    selectDataSetFieldsMandatory_ErrorMessage: "Select the field(s) you require",
    historicMandatory_ErrorMessage: "Select all years that are relevant to you",
    deleteDataSet_ErrorMessagePart1: "Select 'Yes' if you want to delete",
    deleteDataSet_ErrorMessagePart2: "' data set",
    selectDataDuration_ErrorMessage: "Select all data duration options that are relevant to you",
    dataMinimisation_ErrorMessage: "Use the text box to provide information on how you will minimise the data",
    identifiableDataErrorMessage: "Select 'Yes' if the data you are requesting can be used to identify individuals within a Participant group",
    legalBasesIdentifiableDataErrorMessage: "Select the legal basis for receiving identifiable data from NHS England",
    legalBasesMandatory_ErrorMessage: "Check one or more boxes to choose your legal bases",
    legalBasesOtherMandatory_ErrorMessage: "Use the text box to provide information on 'Other'",
    noServiceType_ErrorMessage: "No service types selected in CRM for this product"
}
export const fileTypeSize: IFileTypeSize = {
    fileTypePDF: "application/pdf",
    fileTypeExcel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    fileTypeWord: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    fileSize: "3000",
    fileTypeDoc: "application/msword",
    fileTypeDotx: "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    fileTypeDotm: "application/vnd.ms-word.template.macroEnabled.12",
    fileTypeDocm: "application/vnd.ms-word.document.macroEnabled.12",
    fileTypeXLSB: "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    fileTypeXLSM: "application/vnd.ms-excel.sheet.macroEnabled.12",
    fileTypeXLAM: "application/vnd.ms-excel.addin.macroEnabled.12",
    fileTypeXLS: "application/vnd.ms-excel",
    fileTypeXLTX: "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    fileTypeXLTM: "application/vnd.ms-excel.template.macroEnabled.12",
    fileTypeCSV: "text/csv",
    fileSizeEmpty: '0',
    checkFileLength: 1,
    fileTypeJson: "application/json",
    fileTypeBlob: "application/blob"
}
export const dataSetDetails: IDataSet = {
    serviceType: "Regular"
}

export const dataSetLevelOptionTypes: IDataSetLevelTypeOptions = {
    recordLevelValue: "923180001",
    aggregatedLevelValue: "923180000"
}
export const frequencyOfDataOptionTypes: IDataSetFrequencyTypeOptions = {
    monthlyValue: "923180002",
    quarterlyValue: "923180001",
    annuallyValue: "923180000",
    oneOffValue: "923180003"
}
export const aPYfrequencyOfDataOptionTypes: IDataSetAPYFrequencyTypeOptions = {
    monthlyValue: "923180000",
    quarterlyValue: "923180001",
    annuallyValue: "923180004",
    oneOffValue: "923180002"
}

export const standardisationServiceLevelOptions: IDataSetStandardisationServiceLevelOptionsOptions = {
    OneOffBespokeValue: "100000000",
    RegularBespokeValue: "923180001",
    RegularStandardValue: "923180000",
    SystemAccessValue: "923180002"
}
export const dataSetFieldGroupTypeOptions: IDataSetGroupTypeOptions = {
    generalValue: "1001",
    highRiskValue: "1002",
    identifiableValue: "1003",
}

export const ProductGroupTypeOptions: IProductGroup = {
    Bridge: 923180012,
    Cohort: 923180013,
    Commissioning: 923180001,
    DID: 923180010,
    GPES: 923180008,
    HES: 923180000,
    MentalHealth: 923180005,
    MRIS: 923180003,
    ONS: 923180006,
    PbR: 923180004,
    PDS: 923180011,
    PROMS: 923180009,
    Survey: 923180007,
    SUS: 923180002,
    Other: 923180014,
    ASH: 100000000,
    HDIS: 100000001
}

export const LegalBasesHintText = {
    LB006: "LB006",
    LB020: "LB020",
    LB021: "LB021",
    LB023: "LB023",
    LB024: "LB024",
    LB027: "LB027",
    LB022: "LB022",
}

export const documentType = {
    ISO: 1001,
    SLSP: 1002,
    "Consent_Form": 923180002,
    "Patient_Information": 923180003,
    "Patient_Gp_Letters": 923180004,
    "Section_251_Support": 923180005,
    "Other": 923180007,
    "IRAS_Form": 100000000,
    "Ethics_Review": 923180001,
    "Funding": 923180011,
    "ISO/IEC 27001 ": 923180009,
    "SLSP ": 923180010,
    "Dataflow_Diagram": 923180008,
    "Ons": 923180006,
    "Protocol": 923180000

}

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export const participantErrorMessage: IParticipantErrorMessage = {
    participantType_ErrorMessage: "Select 'New' or 'Existing agreement' to 'What type of Participant data?'",
    legalBasisMandatory_ErrorMessage: "Select the legal basis for sending Participant data to NHS England",
    participantData_ErrorMessage: "Select 'Yes' if you are providing (or have you provided) any Participant data to NHS England for this application?'",
    existingType_ErrorMessage: "Select reference number",
    selectSummary_ErrorMessage: "There is a problem",
    legalBasisOther_ErrorMessage: "Please enter other legal basis description",
    dataProviderFirstNameMandatory_ErrorMessage: "Enter First Name of Participant Data Provider",
    dataProviderLastNameMandatory_ErrorMessage: "Enter Last Name of Participant Data Provider ",
    dataProviderRoleMandatory_ErrorMessage: "Enter Role or Job Title of Participant Data Provider",
    dataProviderOrganisationNameMandatory_ErrorMessage: "Enter Organisation Name of Participant Data Provider",
    dataProviderEmailMandatory_ErrorMessage: "Enter Email Address of Participant Data Provider",
    dataProviderEmailMatch_ErrorMessage: "The Confirmation Email does not match your Email address",
    dataProviderConfirmEmailMandatory_ErrorMessage: "Enter Confirm Email Address of Participant Data Provider",
    selectParticipantSectionCompleteBody_ErrorMessage: "Select 'Yes' if all relevant details for the Participants have been added",
    deleteParticipant_ErrorMessage: "Select 'Yes' if you want to delete Participant Data Provider ",
    linkingParticipant_ErrorMessage: "Selecting only one 'required field' means that you need to select at least one 'recommended field'. Select at least one 'recommended field'.",
    linkingParticipantrequired_ErrorMessage: "Select at least one check box from 'required fields'. If both 'required fields' are selected, you do not have to select from 'recommended fields'.",
    uploadDocumentComment_ErrorMessage: "Use the text box to provide the document name",
    legalBasisOtherMandatory_ErrorMessage: "Use the text box to provide information of 'Other'",
}

export const participantTypeOptions: IParticipantTypeOptions = {
    participantProvidingTheData: "yes",
    participantNotProvidingTheData: "no",
    new: "923180002",
    existing: "923180001",
}

export const dsaSummary: IDSASummary = {
    firstYear: "1",
    secondYear: "2",
    thirdYear: "3",
    dsaDay: "dsaDay",
    dsaMonth: "dsaMonth",
    dsaYear: "dsaYear"
}

export const dsaSummaryErrorMessage: IDSASummaryErrorMessage = {
    enterDSATaskDuration_ErrorMessage: "Select one option to tell us how long you need this DSA",
    enterProjectName_ErrorMessage: "Use the text box to tell us the name of your project",
    selectDataRequestReason_ErrorMessage: "Confirm the reason for your data request",
    enterProposedStartDate_ErrorMessage: "Your proposed agreement's start date must be in the future but by no more than one year"
}

export const fundingSourceTypeOptions: IFundingSourceTypeOptions = {
    Public: "923180000",
    Private: "923180001",
    noFunding: "923180002"
}

export const fundingErrorMessage: IFundingErrorMessage = {
    fundingType_ErrorMessage: "Select whether your funding source is public or private",
    fundingAwardingInstruction_ErrorMessage: "Use the text box to proviide information on 'Funding Awarding institution'",
    referenceTitle_ErrorMessage: "Use the text box to provide information on 'Reference and title of project' ",
    deleteFunding_ErrorMessage: "Select 'Yes' if you want to delete this funding document",
    fundingDocument_ErrorMessage: "Upload funding document",
    day_ErrorMessage: "Provide a 'Day' and select 'Continue'",
    month_ErrorMessage: "Provide a 'Month' and select 'Continue'",
    year_ErrorMessage: "Provide a 'Year' and select 'Continue'",
    noFundingType_ErrorMessage: "Select whether your funding source is public, private or self-funded"
}

export const applicantorPartnerOptions: IApplicantorPartnerOptions = {
    applicant: "923180000",
    partner: "923180001"
}

export const purposeErrorMessage: IPurposeErrorMessage = {
    selectCommerical_ErrorMessage: "Select 'Yes' if any shared data from NHS England will be used for commercial purposes",
    selectCommericalPurpose_ErrorMessage: "Enter how shared data from NHS England would be used for commercial purposes",
    selectSublicensing_ErrorMessage: "Select 'Yes' if you will be sub-licensing the data",
    enterSublicensingDuration_ErrorMessage: "Use the text box to tell us how long you intend to share the data",
    selectCompletedCommercial_ErrorMessage: "Select 'Yes' if you have completed the commercial purposes and sub-licensing section",
    selectExpectedOutput_ErrorMessage: "Use the text box to provide information on 'Outputs'",
    selectExpectedBenefit_ErrorMessage: "Use the text box to provide information on 'Expected Benefits'",
    selectExpectedConfirmation_ErrorMessage: "Select 'Yes' if you have completed the 'Expected Outputs and Benefits' section ",
    enterObjective_ErrorMessage: "Use the text box to tell us the objective(s) for processing the data",
    enterProcessingActivities_ErrorMessage: "Use the text box to provide information on your processing activities",    
    selectObjectiveAndProcessing_ErrorMessage: "Select 'Yes' if you have completed the 'Objective(s) and Processing Activities' section"
}

export const yieldedBenefitsErrorMessageConfig: IYieldedBenefits_ErrorMessage = {
    enterYieldedBenefits_ErrorMessage: "Use the text box to provide information on your yielded benefits"   
}

export const evidenceTypeOptions: IEvidenceTypeOptions = {
    "Protocol": { value: 923180000, name: "Protocol" },
    "EthicsReview": { value: 923180001, name: "Ethics Review" },
    "ConsentForm": { value: 923180002, name: "Consent Form", legalBases: [923180001, 923180006, 923180007], legalBasis: ["LB026"] },
    "PatientInformation": { value: 923180003, name: "Patient Information", legalBases: [923180001, 923180006, 923180007], legalBasis: ["LB026"] },
    "PatientGpLetters": { value: 923180004, name: "Patient/GP Letters", legalBases: [923180001, 923180006, 923180007], legalBasis: ["LB026"] },
    "Section251Support": { value: 923180005, name: "Section 251 Support", legalBases: [923180002, 923180005, 923180007], legalBasis: ["LB003"] },
    "Ons": { value: 923180006, name: "ONS" },
    "Other": { value: 923180007, name: "Other", legalBases: [923180004] },
    "DataflowDiagram": { value: 923180008, name: "Dataflow Diagram" },
    "IRASReference": { value: 100000000, name: "IRAS Form", legalBases: [923180002, 923180005, 923180007], legalBasis: ["LB003"] },
    "ISO": { value: 923180009, name: "ISO/IEC 27001" },
    "SLSP": { value: 923180010, name: "SLSP" },
    "Funding": { value: 923180011, name: "Funding" }
}

export const legalBasesIdentifiableDataOptions: ILegalBasesIdentifiableDataOptions = {
    Consent_ReasonableExpectation: "923180001",
    Doesnotincludetheflowofconfidentialdata: "923180000",
    Mixtureofconfidentialdataflow_swithconsentandflow_swithsupportundersection251NHSAct2006: "923180007",
    Mixtureofconfidentialdataflow_swithconsentandnonconfidentialdataflow_s: "923180006",
    Mixtureofconfidentialdataflow_swithsupportundersection251NHSAct2006andnonconfidentialdataflow_s: "923180005",
    Section251NHSAct2006: "923180002",
    Statutoryexemptiontoflowconfidentialdatawithoutconsent: "923180004"
}
export const evidenceErrorMessage: IEvidenceErrorMessage = {
    isAnotherEvidence_ErrorMessage: "Select 'Yes' or 'No' to 'do you want to add another document?' ",
    evidenceType_ErrorMessage: "Select the type of evidence that you will be uploading",
    evidenceComment_ErrorMessage: "Use the text box to provide the document name",
    evidenceFile_ErrorMessage: "Upload a file",
    evidenceApproval_ErrorMessage: "Select 'Yes' or 'No' to 'Does your purpose requires ethics approval?'",
    ethicsApproval_ErrorMessage: "Select 'Yes' or 'No' to 'Is the relevant ethics approval in place?'",
    noEthicsApproval_ErrorMessage: "Use the text box to tell us why your purpose does not require ethics approval",
    ethicsApprovalNotInPlace_ErrorMessage: "Use the text box to tell us why your ethics approval is not in place",
    deleteDocument_ErrorMessage: "Select 'Yes' or 'No' to 'Are you sure you want to delete this document?'",
    uploadEvidence_ErrorMessage: "Select 'Yes' or 'No' to continue"
}
export const locations: ILocations = {
    "England and Wales": "923180000",
    'UK': "923180001",
    'UK and EEA': "923180002",
    'Worldwide': "923180003",
};

export const processAndStoragelocationsErrorMessage: IProcessAndStorageLocationsErrorMessage = {
    processingLocationErrorMessage: "Make a selection and select 'Continue'",
    storageLocationErrorMessage: "Make a selection and select 'Continue'"
}
export const applicationChangeType: IApplicationChangeType = {
    new: "923180000",
    Extension: "923180001",
    Renewal: "923180002",
    Amendment: "923180003",
    Close: "000000000"
}

export const EthicsApprovalInPlaceTypeOptions: any = {
    Yes: "923180000",
    No: "923180001",
    Na: "923180002"
}
export const DoesPrivacyNoteMeetTheCriteriaTypeOptions: any = {
    Yes: "923180000",
    No: "923180001"
}
export const privacyNoticeErrorMessage: IPrivacyNoticeErrorMessage = {
    privacyNotice_ErrorMessage: "Select 'Yes' to confirm the below statement",
    explanationPrivacyNotice_ErrorMessage: "Use the text box to tell us why you answered 'No' on the previous page"

}

export const submitApplicationErrorMessage: ISubmitApplicationErrorMessage = {
    dsaConfirm_ErrorMessage: "Check the box to confirm the statement",
    dsaPONumber_ErrorMessage: "Make a selection and select 'Continue'"
}

export const ProcessAndStorageLocationsValue: IProcessAndStorageLocationsValue = {
    Processor: 923180001,
    Storage: 923180002,
}

export const amendRenewExtendValue: IAmendRenewExtend = {
    renew: "renew",
    amend: "amend",
    extend: "extend",
}

export const pageConfigList: IPageConfigList = {
    Help: 1001,
    AboutUs: 1002,
    DatasetSelectionPage: 1003,
    LegalBasisDatasetJourney: 1004
}

export const initialStatePageConfig: IPageConfig = {
    PageId: 0,
    PageLastReviewed: "",
    NextReviewDue: ""
}

export const legalBasisValue: ILegalBasisValue = {
    consentValue: "LB026",
    nhsHealthValue: "LB003",
    checkLegalBasisLength: 0,
    otherLegalBasisValue: "LB006"
}

export const SmallNumbersVisibleOrSupressed: ISmallNumbersVisibleOrSupressed = {
    SmallNumbersNotSupressed: 923180002,
    SmallNumbersSupressed: 923180000
}

export const legalBasisText = {
    other: "other",
    consentReasonableExpectation: "consent (reasonable expectation)",
}

export const routeName = {
    approvalEvidenceData: "/dsa/approval-evidence-data",
    addAnotherFileRecord: "/dsa/add-another-dataset-record",
    addAnotherEthicsReview: "/dsa/add-another-ethics-review",
    addAnotherApprovalEvidence: "/dsa/add-another-approval-evidence",
    uploadConsent: "/dsa/upload-consent",
    uploadNhsHealth: "/dsa/upload-nhs-health",
    uploadedFiles: "/dsa/uploaded-files",
    serviceError: "/serviceerror",
    participantSummary: "/dsa/participant-summary",
    addedAllDataSet: "/dsa/added-all-dataset",
    dsaTaskList: "/dsa/task-list",
    evidenceType: "/dsa/evidence-type",
    legalBasesIdentifiableData: "/dsa/legal-bases-identifiable-data",
    legalBasis: "/dsa/legal-basis",
    legalBases: "/dsa/legal-bases",
    deleteDocument: "/dsa/delete-document",
    fundingDocument: "/dsa/funding-document",
    dsfcDetails: "/dsfc/details",
    dsfcDownload: "/dsfcdownload",
    dsfcIntro: "/dsfc/introduction",
    dsfcApprove: "/dsfc/approve",
    uploadDocumentDetails: "/dsa/uploaded-document-details",
    fundingType: "/dsa/funding-type",
    serviceUnavailable: "/serviceunavailable",
    privacyNotice: "/dsa/privacy-notice",
    dataRequestReason: "/dsa/data-request-reason",
    declaration: "/dsa/declaration",
    uploadSupportingEvidence: "/dsa/upload-supporting-evidence",
    processorDetails: "/dsa/processor-details",
    solelyProcessor: "/dsa/solely-processor",
    processorData: "/dsa/processor-data",
    controllerDetails: "/dsa/controller-details",
    controllerType: "/dsa/controller-type",
    datasetDetails: "/dsa/dataset-details",
    datasetIntro: "/dsa/dataset-introduction",
    participantData: "/dsa/participant-data",
    objectiveProcessingCheckAnswer: "/dsa/objective-processing-check-answer",
    objectiveProcessing: "/dsa/objective-processing",
    summaryCommercial: "/dsa/summary-commercial",
    commercial: "/dsa/commercial",
    expectedCheckYourAnswer: "/dsa/expected-check-your-answers",
    expectedOutputs: "/dsa/expected-outputs",
    selectLocation: "/dsa/select-locations",
    evidenceApproval: "/dsa/evidence-approval",
    managedsa: "/dsa/manage-dsa",
    chooseAmendSection: "/dsa/choose-section",
    amendBeforeYouStart: "/dsa/amend-before-you-start",
    dsaBeforeYouStart: "/dsa/dsa-before-you-start",
    redirectController: "/redirectcontroller",
    noPermission: "/nopermission",
    applyDsa: "/dsa/apply-dsa",
    activeDsa: "/dsa/active-dsa",
    dashboard: "/dsfc/dashboard",
    dsfcSubmitContract: '/dsfc/submit-contract',
    noPrivacyNotice: '/dsa/no-privacy-notice',
    completedCommercialSection: '/dsa/completed-commercial-section',
    completedObjectiveProcessing: '/dsa/completed-objective-processing',
    completedExpectedSection: '/dsa/completed-expected-section',
    relevantParticipantData: '/dsa/relevant-participant-data',
    processingActivities: '/dsa/processing-activities',
    sublicensingDuration: '/dsa/sublicensing-duration',
    downloadActiveDsa: '/dsa/download-active-dsa',
    controllerData: '/dsa/controller-data',
    payForThisService: '/dsa/pay-for-this-service',
    processingData: '/dsa/processing-data',
    completedControllerSection: '/dsa/completed-controller-section',
    jointSoleController: '/dsa/joint-sole-controller',
    dataProtectionRegistration: '/dsa/data-protection-registration',
    securityAssurance: '/dsa/security-assurance',
    dsptDetails: '/dsa/dspt-details',
    dsaIso: '/dsa/iso',
    dsaSlsp: '/dsa/slsp',
    addAnotherSlsp: '/dsa/add-another-slsp',
    selectDatasetField: '/dsa/select-dataset-field',
    commercialPurpose: '/dsa/commercial-purpose',
    dsaSublicensing: '/dsa/sublicensing',
    processorSecurityAssurance: '/dsa/processor-security-assurance',
    dataMinimisation: '/dsa/data-minimisation',
    datasetFrequency: '/dsa/dataset-frequency',
    historicAnnualData: '/dsa/historic-annual-data',
    manageBeforeYouStart: '/dsa/manage-before-you-start',
    closingAgreement: '/dsa/closing-agreement',
    addedAllProcessors: '/dsa/added-all-processors',
    dataProtectionRegistrationProcessor: '/dsa/data-protection-registration-processor',
    processorDsptDetails: '/dsa/processor-dspt-details',
    processorIso: '/dsa/processor-iso',
    processorSlsp: '/dsa/processor-slsp',
    addAnotherprocessorSlsp: '/dsa/add-another-processorslsp',
    selectDataset: '/dsa/select-dataset',
    identifiableData: '/dsa/identifiable-data',
    dsaConfirmation: '/dsa/confirmation',
    dsaArrove: '/dsa/approve',
    downloadApplyDsa: '/dsa/download-apply-dsa',
    addAnotherController: '/dsa/add-another-controller',
    dataDuration: '/dsa/data-duration',
    aggredatedRecord: '/dsa/aggregated-record',
    proposedDate: '/dsa/proposed-date',
    proposedDsaTerm: '/dsa/proposed-dsa-term',
    dataRequestProject: '/dsa/data-request-project',
    ethicsApprovalNotInPlace: '/dsa/ethics-approval-not-in-place',
    ethicsApproval: '/dsa/ethics-approval',
    noEthicsApproval: '/dsa/no-ethics-approval',
    whoWillProvide: '/dsa/who-will-provide',
    addCohortContributor: '/dsa/add-cohort-contributor',
    linkingParticipant: '/dsa/linking-participant',
    noExistingAgreement: '/dsa/no-existing-agreement',
    existingType: '/dsa/existing-type',
    participantTypes: '/dsa/participant-types',
    dsaCohort: '/dsa/cohort',
    latestSignedContract: '/dsfc/latest-signed-contract',
    deleteProcessor: '/dsa/delete-processor',
    dsaSubmit: '/dsa/submit',
    fundingInformation: '/dsa/funding-information',
    addAnotherFunding: '/dsa/add-another-funding',
    dsfcPreview: '/dsfc/preview',
    dsfcSignatory: '/dsfc/signatory',
    latestNewsDetails: '/latest-news/news-details',
    dsfcAgree: '/dsfc/agree',
    help: '/help',
    latestNews: '/latest-news',
    guidance: '/guidance',
    datasetCheckYourAnswer: '/dsa/dataset-check-your-answer',
    deleteDataset: '/dsa/delete-dataset',
    deleteFunding: '/dsa/delete-funding',
    aboutUs: '/aboutus',
    contactUs: '/contactus',
    addOrganisationDetails: '/dsa/add-organisation-details',
    addProcessorOrganisation: '/dsa/add-processor-organisation',
    expectedBenefits: '/dsa/expected-benefits',
    deleteParticipant: '/dsa/delete-participant',
    taskDuration: '/dsa/task-duration',
    deleteController: '/dsa/delete-controller',
    dsaSign: '/dsa/sign',
    aboutController: '/dsa/about-controller',
    resetUserDetailData: 'header/resetUserDetailsData',
    rootRoute: '/',
    manageDsaBeforeYouStart: '/dsa/manage-before-you-start',
    yieldedBenefits: '/dsa/yielded-benefits',
    completedYieldedBenefits: '/dsa/completed-yielded-benefits',
    signOut: "/signout",
    accessibility: '/accessibiltiy',
    poNumber: '/dsa/poNumber',
}

export const regexValidateString = {
    alphabets: /^[A-Za-z\s]+$/,
    numericAlphabets: /^[0-9a-zA-Z\s]+$/,
    numeric: /^\d+$/,
    email: /^[^\s@]+@[^\s@]+\.[a-zA-z]{2,}$/
}

export const latestNewsContent = {
    Next: "Next",
    Previous: "Previous"
}

export const maxLength = {
    linkingParticipantText: 2000,
    ethicsApprovalNotInPlaceText: 1000,
    referenceAndTitleProject: 100,
    awardingInstitution: 100
}

export const retryLimit = 2;

export const serverResponse = {
    unauthorized: 401,
    success: 200,
    noContent: 204,
    resourceCreated: 201
};

export const sectionStatusTaskList = {
    processor: false,
    expectedOutputsBenefits: false,
    commercialPurposesSublicensing: false,
    participants: false,
    aboutthedata: false,
    submitApplication: false,
    yieldedBenefits:false
}

export const expandCollapse = {
    defaultCharLimit: 500,
    defaultFileLength: 3,
    show: "+ Show",
    hide: "- Hide",
}

export const inactivityDuration = 60000 * 25; // 25 minutes
export const debounceDuration = 1000 * 1; // 1 second
export const abortError = "AbortError";
export const defaultDuration = 200;

export const initialStateOfFileUpload: IFileUpload = {
    ContentType: "",
    Data: {
        _buffer: "",
        _origin: 0,
        _position: 0,
        _length: "",
        _capacity: "",
        _expandable: false,
        _writable: false,
        _exposable: false,
        _isOpen: false,
        __identity: null,
    },
    FileName: "",
    DocumentType: 0,
    ApprovalEvidenceID: "00000000-0000-0000-0000-000000000000",
    ApplicantComments: '',
    ApprovalEvidenceType: 0,
    SelectedEvidenceUpload: 0,
    SectionID: 0,
    SectionType: '',
    RecentlyModified: false,
}
export const sectionGroupListConfig: ISectionList = {
    "ParentSection": [
        {
            "SectionName": "OrganisationsInvolved",
            "SectionIDs": [controllerSection.aboutController,
            controllerSection.PrivacyNotice,
            controllerSection.aboutProcessor,
            controllerSection.ProcessingLocations,
            controllerSection.StorageLocations,
            controllerSection.Funding]
        },
        {
            "SectionName": "ApplyingForData",
            "SectionIDs": [controllerSection.ProjectTitleReason,
            controllerSection.ObjectiveAndProcessingActivities,
            controllerSection.ExpectedOutputsAndBenefits,
            controllerSection.CommercialPurposesAndSublicensing]
        },
        {
            "SectionName": "DataAndParticipant",
            "SectionIDs": [controllerSection.aboutData,
            controllerSection.Participants,
            controllerSection.EthicsApproval]
        },
        {
            "SectionName": "ApplicationOverview",
            "SectionIDs": [controllerSection.ListOfUploadedDocuments,
            controllerSection.SubmitApplication]
        }

    ]
}

export const dataRequestReason = {
    "submissionofAdvice":557340007
}

export const compuslsorySectionsAmend = [557340000, 557340001, 557340015, 557340011, 557340006];
export const ApplicationwithOptions = {
    /// <summary>With Customer</summary>
    WithCustomer : 923180000,

    /// <summary>With Hscic</summary>
    WithHscic : 923180001
}

export const ProcessStageOptions = {
    /// <summary>Qualify</summary>
    Qualify : 0,

    /// <summary>Develop</summary>
    Develop : 1,

    /// <summary>Propose</summary>
    Propose : 2,

    /// <summary>Close</summary>
    Close : 3,

    /// <summary>Identify</summary>
    Identify : 4,

    /// <summary>Research</summary>
    Research : 5,

    /// <summary>Resolve</summary>
    Resolve : 6,

    /// <summary>1a Enquiry</summary>
    Enquiry : 923180000,

    /// <summary>1b Enquiry Answered</summary>
    EnquiryAnswered : 923180001,

    /// <summary>1c Application In Progress</summary>
    ApplicationInProgress : 923180002,

    /// <summary>2A Case Admin Check</summary>
    CheckCaseAdministrator : 923180003,

    /// <summary>2b Check Case Officer</summary>
    CheckCaseOfficer : 923180004,

    /// <summary>2c Initial Assessment</summary>
    InitialAssessment : 923180005,

    /// <summary>2d Action Plan Active</summary>
    ActionPlanActive : 923180006,

    /// <summary>3a With DAO</summary>
    WithDao : 923180007,

    /// <summary>3b With IAO</summary>
    WithIao : 923180008,

    /// <summary>4a Approvals Gateway</summary>
    ApprovalsGateway : 923180009,

    /// <summary>4b Approvals Group</summary>
    ApprovalsGroup : 923180010,

    /// <summary>4c IAO Sign DSA</summary>
    IaoSignDsa : 923180011,

    /// <summary>4d Director Approval</summary>
    DirectorApproval : 923180012,

    /// <summary>4e With Customer To Sign DSA</summary>
    WithCustomerToSignDsa : 923180013,

    /// <summary>5a DSA Active</summary>
    DsaActive : 923180016,

    /// <summary>6a Deletion Notice Required</summary>
    DeletionNoticeRequired : 923180017,

    /// <summary>6b Deletion Notice Served</summary>
    DeletionNoticeServed : 923180018,

    /// <summary>6c Deletion Received</summary>
    DeletionReceived : 923180019
}