import { Button, Label, InsetText, Table } from "nhsuk-react-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ControllerHeader from "../../../../Components/Shared/ControllerHeader";
import { applicationChangeType, dataSetLevelOptionTypes, dsaContent, fileTypeSize, frequencyOfDataOptionTypes, routeName, standardisationServiceLevelOptions, } from "../../../../config/page.config";
import { useAppDispatch, useAppSelector } from "../../../../state/app/hooks";
import { AppDispatch } from "../../../../state/app/store";
import { IApplication, IDataSet, IDataSetFrequency, IDataSetList, IHistoricAnnualData, IDataSetFieldList, IDataSetFieldGroupList, ILegalBasesCover, IApiResDataDurationDetails, IApiResDataSetFieldList, IApiResDataSetFieldGroupList, IApiResDataSetList, IApiResAssociatedProductList, IApiResFrequencies, IApiResLegalBasesCover } from "../../../../state/models/app.interface";
import { getDataSetDetails, setDataSetDetailsData } from "../../../../state/slice/DataSet/DataSet.slice";
import Loader from "../../../../Components/Loader/Loader";
import GoBack from "../../../../Components/Shared/GoBack";
import { checkApplicationChangeType, fetchInterceptor, getCookie, getTaskListType, isValid, submitSpinner } from "../../../../Helper/Utility";
import { getDSADetails } from "../../../../state/slice/DSA/DSA.slice";
import { GetAssociatedProductList_URL } from "../../../../config/api-endpoints.config";
import { getDataSetListDetails, setDataSetListDetailsData } from "../../../../state/slice/DataSetList/DataSetList.slice";

const DataSetDetails: React.FC = () => {
    let navigate = useNavigate();
    const authenticationTokenCookie: string = getCookie('authenticationToken')!;
    const dsaApplicationDetails: IApplication = useAppSelector(getDSADetails);
    const initialApplicationState: IApplication = dsaApplicationDetails || {};
    const [dsaApplicationData] = useState(initialApplicationState);    

    const dispatch: AppDispatch = useAppDispatch();
    const dataSetDetails: IDataSet = useAppSelector(getDataSetDetails);
    const initialDataSetState: IDataSet = dataSetDetails || {};
    const [dataSetData, setDataSetData] = useState(initialDataSetState);

    const dataSetListDetails: IDataSetList = useAppSelector(getDataSetListDetails);
    const initialDataSetListState: IDataSetList = dataSetListDetails || {};
    const [dataSetListData, setDataSetListData] = useState(initialDataSetListState);

    const [dataSetdataList, setDataSetdataList] = useState<IDataSet[]>();
    const saveDataInStore = (stateData?: IDataSet) => {
        dispatch(setDataSetDetailsData({ ...dataSetData, ...stateData }));
    }

    const saveDataListInStore = (stateData?: IDataSetList) => {
        dispatch(setDataSetListDetailsData({ ...dataSetListData, ...stateData }));
    }
    const [loading, setLoading] = useState(false);

    const getAssociatedDataSetData = () => {
        setLoading(true);
        const parameters: string = JSON.stringify({
            "UserAuthTokenId": authenticationTokenCookie,
            "ApplicationId": dsaApplicationData.id !== "" ? dsaApplicationData.id : ""
        });
        const requestOptions: Object = {
            method: 'POST',
            headers: { 'Content-Type': fileTypeSize.fileTypeJson },
            body: parameters
        };
        fetchInterceptor(GetAssociatedProductList_URL, requestOptions)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setAssociatedDataSetData(data);

            })
            .catch(() => navigate(routeName.serviceError));
    }
    const setAssociatedDataSetData = (response: IApiResAssociatedProductList) => {
        if (response && response !== undefined && response?.AssociatedProductList !== null && response?.AssociatedProductList!) {
            const dataSetList = response?.AssociatedProductList;
            let updatedState: IDataSetList = {};
            let dataSetDetails: IDataSet = { ...dataSetData };
            let dataSetDetailsList: IDataSet[] = [];
            let datasetlist: IDataSet[] = [];
            let historicData: IHistoricAnnualData;
            let dataSetLegalBasesData: ILegalBasesCover;

            if (dataSetList.length > 0) {
                dataSetList.map((dataSet: IApiResDataSetList) => {
                    let arrhistoricAPYData: IHistoricAnnualData[] = [];
                    let arrexistingHistoricAPYData: IHistoricAnnualData[] = [];
                    let arrSelectedFieldData: IDataSetFieldList[] = [];
                    let selectedGroupList: IDataSetFieldGroupList[] = [];
                    let arrcurrentdataSetLegalBasesData: ILegalBasesCover[] = [];

                    /*Historic Data Bind*/
                    if (dataSet?.MappedAnnualPeriodsList !== null && dataSet?.MappedAnnualPeriodsList.length >0 && dataSet?.MappedAnnualPeriodsList!) {
                        dataSet?.MappedAnnualPeriodsList.forEach((item: IApiResDataDurationDetails) => {
                            historicData = {
                                periodValue: item?.PeriodValue,
                                fileAvailabilityId: item?.FileAvailabilityId,
                                period: item?.Period,
                                displayText: item?.DisplayText,
                                selected: item?.Selected,
                                periodAreadyMappedToFileAvailabilityInCRM: item?.PeriodAreadyMappedToFileAvailabilityInCRM,
                                alreadyHeld: item?.AlreadyHeld,
                                version: item?.Version
                            };
                            if (item.Selected || item.AlreadyHeld) {
                                arrhistoricAPYData.push(historicData);
                            }
                            arrexistingHistoricAPYData.push(historicData);
                        });
                    }
                    else {
                        if (dataSet?.CurrentCRMDatasetTimePeriods !== null && dataSet?.CurrentCRMDatasetTimePeriods!) {
                            dataSet?.CurrentCRMDatasetTimePeriods.forEach((item: IApiResDataDurationDetails) => {
                                historicData = {
                                    periodValue: item?.PeriodValue,
                                    fileAvailabilityId: item?.FileAvailabilityId,
                                    period: item?.Period,
                                    displayText: item?.DisplayText,
                                    selected: item?.Selected,
                                    periodAreadyMappedToFileAvailabilityInCRM: item?.PeriodAreadyMappedToFileAvailabilityInCRM,
                                    alreadyHeld: item?.AlreadyHeld,
                                    version: item?.Version
                                };
                                if (item.Selected || item.AlreadyHeld) {
                                    arrhistoricAPYData.push(historicData);
                                }
                                arrexistingHistoricAPYData.push(historicData);
                            });
                        }
                    }
                    /*Dataset legal bases bind*/
                    if (dataSet?.DataSetLegalBasisList !== null && dataSet?.DataSetLegalBasisList!) {
                        dataSet?.DataSetLegalBasisList.forEach((item: IApiResLegalBasesCover) => {
                            dataSetLegalBasesData = {
                                value: item?.Value,
                                name: item?.Name,
                                checked: item?.Checked,
                                text: item?.Text,
                                crmId: item?.crmId,
                                processing: item?.Processing,
                                dissemination: item?.Dissemination,
                                otherLegalBasis: item?.OtherLegalBasis,
                                displayProcessing: item?.DisplayProcessing,
                                displayDissemination: item?.DisplayDissemination,
                                productLegalBasis: item?.ProductLegalBasis,
                                otherComments: item?.OtherComments,
                                orderBy: item?.OrderBy,
                                availableOnline: item?.AvailableOnline,
                                currentlyCheckedInCRM: item?.CurrentlyCheckedInCRM,
                                notes: item?.Notes,
                                description: item?.Description
                            };
                            arrcurrentdataSetLegalBasesData.push(dataSetLegalBasesData);
                        });
                    }

                    /*Required Fields Bind*/
                    if (dataSet?.DataSetFieldGroupList !== null) {
                        dataSet?.DataSetFieldGroupList?.forEach((items: IApiResDataSetFieldGroupList) => {
                            let groupFieldsDataList: IDataSetFieldList[] = [];
                            items?.GroupFieldList?.forEach((item: IApiResDataSetFieldList) => {
                                const groupFieldListData: IDataSetFieldList = {
                                    id: item?.Id,
                                    groupName: item?.GroupName,
                                    fieldName: item?.FieldName,
                                    fieldLogicalName: item?.FieldLogicalName,
                                }
                                arrSelectedFieldData.push(groupFieldListData);
                                groupFieldsDataList.push(groupFieldListData);
                            })
                            const dataSetFieldGroupList: IDataSetFieldGroupList = {
                                groupName: items?.GroupName,
                                groupId: items?.GroupId,
                                groupFieldList: groupFieldsDataList
                            }
                            selectedGroupList.push(dataSetFieldGroupList);
                        })
                    }
                    let frequencies: IDataSetFrequency = {};
                    let frequencyDataArr: IDataSetFrequency[] = [];
                    if (dataSet !== undefined && dataSet !== null) {
                        if (dataSet?.Frequencies !== undefined && dataSet?.Frequencies !== null && dataSet?.Frequencies!) {
                            dataSet?.Frequencies?.map((item: IApiResFrequencies) => {
                                frequencies = { value: item?.Value };
                                frequencyDataArr = Object.assign([], frequencyDataArr);
                                frequencyDataArr.push(frequencies);
                            })
                        }
                    }
                    dataSetDetails = {
                        ...dataSetData,
                        description: dataSet?.Description,
                        crmUID: dataSet?.CRMUID,
                        crmProductId: dataSet?.CRMProductId,
                        crmAssociatedProductId: dataSet?.CRMAssociatedProductId,
                        name: dataSet?.Name,
                        serviceTypeOneOff: dataSet?.ServiceTypeOneOff,
                        serviceTypeRegularBespoke: dataSet?.ServiceTypeRegularBespoke,
                        serviceTypeRegularStandard: dataSet?.ServiceTypeRegularStandard,
                        serviceTypeSystemAccess: dataSet?.ServiceTypeSystemAccess,
                        standardisationServiceLevelOptionsSelected: dataSet?.StandardisationServiceLevelOptionsSelected,
                        aboutDataSectionStatus: "",
                        aggregatedOrRecordLevelData: dataSet?.AggregatedOrRecordLevelData,
                        datasetFrequency: dataSet?.HowOftenReceiveDataset,
                        historicAnnualDataList:arrhistoricAPYData,
                        selectedProductFieldsList: arrSelectedFieldData,
                        currentYTDExists: dataSet?.CurrentYearToLatestSelected,
                        linkedToCohort: dataSet?.LinkedToCohort,
                        latestRelease: dataSet?.DataDurationLatestAvailable,
                        futureRelease: dataSet?.DataDurationFutureRelease,
                        historicAnnual: dataSet?.DataDurationHistoricAnnual,
                        groupByFieldsList: selectedGroupList,
                        updatedDaily: dataSet.UpdatedDaily,
                        datasetCohort: dataSet?.LinkedToCohort ? dsaContent.yes : dsaContent.no,
                        currentCRMSelectedDatasetFields: arrSelectedFieldData,
                        existingHistoricAnnualDataList: arrexistingHistoricAPYData,
                        serviceType: dsaContent.dataDissemination,
                        stepsMinimiseDataRequested: dataSet.StepsMinimiseDataRequested,
                        legalBasesIdentifiableData: response.CommonLawDutyOfConfidentialityType ? String(response.CommonLawDutyOfConfidentialityType) : undefined,
                        identifiableData: response?.CommonLawDutyOfConfidentialityType ? dsaContent.yes : "",
                        currentDataSetLegalBasesList: arrcurrentdataSetLegalBasesData,
                        dataSetLegalBasesList: arrcurrentdataSetLegalBasesData,
                        frequencylist: frequencyDataArr,
                        initialHistoricAnnualDataList: [initialStateHistoricData]
                    }
                    dataSetDetailsList.push(dataSetDetails);
                })
                datasetlist = Object.assign([], datasetlist);
                datasetlist = dataSetDetailsList;
                setDataSetdataList(datasetlist);
                saveDataInStore(dataSetDetails);
                updatedState = {
                    ...dataSetListData,
                    associatedProduct: dataSetDetailsList,
                    activeDataSet: dataSetList.length
                }
                setDataSetListData(updatedState);
                saveDataListInStore(updatedState);
            }
        }
        setLoading(false);
    }
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        submitSpinner(e, handleClickNavigate);
    }
    const handleClickNavigation = (navigatePage: string, datasetIndex: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        setData(datasetIndex);
        let updatedState: IDataSetList = {};
        updatedState = {
            ...dataSetListData,
            activeDataSet: datasetIndex,
        }
        setDataSetListData(updatedState);
        saveDataListInStore(updatedState);
        navigate(navigatePage);
    }

    const setData = (datasetIndex: number) => {
        let updatedState: IDataSet = {};
        updatedState = dataSetListData.associatedProduct ? dataSetListData.associatedProduct[datasetIndex] : {};
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
    }
    const handleClickNavigate = () => {
        if (dataSetListData?.associatedProduct?.filter(x => x !== undefined && x !== null && x.crmAssociatedProductId !== "").length === 0) {
            navigate(routeName.selectDataset);
        }
        else if (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)])) {
            navigate(routeName.addedAllDataSet);
        }
        else {
            navigate(routeName.identifiableData);
        }
    }
    const handleAnotherDataSet = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        let updatedState: IDataSet = {}
        updatedState = {
            dataDissemination: false,
            serviceType: "",
            description: "",
            crmUID: "",
            crmProductId: "",
            crmAssociatedProductId: "",
            name: "",
            aggregatedOrRecordLevelData: "",
            linkedToCohort: false,
            datasetFrequency: "",
            historicAnnualDataList: [initialStateHistoricData],
            serviceTypeOneOff: false,
            serviceTypeRegularBespoke: false,
            serviceTypeRegularStandard: false,
            serviceTypeSystemAccess: false,
            standardisationServiceLevelOptionsSelected: "",
            frequencylist: [initialStateOfDataSetFrequency],
            aboutDataSectionStatus: "",
            datasetCohort: "",
            selectedProductFieldsList: [initialStateFieldGroupList],
            firstPointer: 0,
            selectedPaginationPage: 0,
            updatedDaily: false,
            annualPeriodsExist: false,
            currentYTDExists: false,
            latestRelease: false,
            futureRelease: false,
            historicAnnual: false,
            initialHistoricAnnualDataList: [initialStateHistoricData],
            groupByFieldsList: [initialStateGroupFieldList],
            currentCRMSelectedDatasetFields: [initialStateFieldGroupList],
            existingHistoricAnnualDataList: [initialStateHistoricData],
            stepsMinimiseDataRequested: "",
            identifiableData: "",
            legalBasesIdentifiableData: "",
            dataSetLegalBasesList: [initialLegalBases],
            currentDataSetLegalBasesList: [initialLegalBases],
            dataSetSelectedLegalBasesOtherText: ""
        }
        setDataSetData(updatedState);
        saveDataInStore(updatedState);
        navigate(routeName.selectDataset);
    }

    const initialStateFieldGroupList: IDataSetFieldList = {
        id: "",
        groupFieldId: "",
        groupName: "",
        fieldName: "",
        fieldType: "",
        fieldLogicalName: "",
        sensitiveFlag: false,
        identifiableFlag: false,
        isGroupField: false,
        isPrimaryField: false,
        description: "",
        startDate: "",
        endDate: "",
        groupType: ""

    }

    const initialStateOfDataSetFrequency: IDataSetFrequency = {
        value: ""
    }
    const initialStateHistoricData: IHistoricAnnualData = {
        period: "",
        fileAvailabilityId: "",
        periodValue: "",
        displayText: "",
        selected: false,
        periodAreadyMappedToFileAvailabilityInCRM: false,
        alreadyHeld: false,
        version:""
    }

    const initialStateGroupFieldList: IDataSetFieldGroupList = {
        groupName: "",
        groupId: "",
        groupFieldList: [initialStateFieldGroupList]
    }
    const initialLegalBases: ILegalBasesCover = {
        value: "",
        crmId: "",
        text: "",
        checked: false,
        name: "",
        availableOnline: false,
        currentlyCheckedInCRM: false,
        displayDissemination: false,
        displayProcessing: false,
        dissemination: false,
        notes: "",
        orderBy: 0,
        otherComments: "",
        otherLegalBasis: false,
        processing: false,
        productLegalBasis: false,
        description: ""
    }

    const isAssociatedProductYearsAvailable = (data: IHistoricAnnualData[] = []) => {
        return isValid(data) && data.filter(item => item.alreadyHeld).length > 0;
    };

    useEffect(() => {
        getAssociatedDataSetData();
    }, [])

    return (
        <>
            <ControllerHeader></ControllerHeader>
            <div className="nhsuk-u-padding-bottom-5"></div>

            {loading ?
                <Loader loadingText={dsaContent.txtLoading} />
                : null}
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper " id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        {!loading ?
                            <>
                                <div className="nhsuk-grid-row">
                                    <div className="nhsuk-grid-column-full">
                                        {
                                            <GoBack condition={getTaskListType(dsaApplicationData.returningJourneyType!)} />
                                        }
                                        <span className="nhsuk-caption-l nhsuk-caption--bottom">
                                            About the data </span>
                                        <h1>You have added the below data set(s) </h1>
                                        {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ?null:
                                            <InsetText>
                                                <p>If you do not need to add another data set(s), select 'Continue'. </p>
                                            </InsetText>}
                                        {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? <Label>Here you can view and add additional years to the selected data set(s)</Label> : <Label>Here you can view or delete your selected data set(s). </Label>}
                                        <Table>
                                            <Table.Head>
                                                <Table.Row>
                                                    <Table.Cell>Data set </Table.Cell>
                                                    <Table.Cell>Link to cohort </Table.Cell>
                                                    <Table.Cell>Data duration </Table.Cell>
                                                    <Table.Cell>Type</Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell></Table.Cell>
                                                </Table.Row>
                                            </Table.Head>
                                            <Table.Body>

                                                {
                                                    dataSetdataList?.map((dataSetData: IDataSet, index: number) => (

                                                        <Table.Row key={index + 1}>
                                                            <Table.Cell>{dataSetData?.name} </Table.Cell>
                                                            <Table.Cell>{dataSetData?.linkedToCohort ? dsaContent.yes : dsaContent.no}</Table.Cell>
                                                            <Table.Cell>
                                                                {(dataSetData?.latestRelease && dataSetData.updatedDaily) ? <p>- Latest Release</p> : (dataSetData?.latestRelease && !dataSetData.updatedDaily) ? <p>- Year-to-date data</p> : ""}
                                                                {dataSetData?.futureRelease ? <p>- Future release data <br /> {String(dataSetData?.datasetFrequency) === String(frequencyOfDataOptionTypes.monthlyValue) ? "(" + dsaContent.monthlyFrequency + ")" : (String(dataSetData?.datasetFrequency) === String(frequencyOfDataOptionTypes.quarterlyValue) ? "(" + dsaContent.quarterlyFrequency + ")" : "(" + dsaContent.annuallyFrequency + ")")}</p> : ""}
                                                                {dataSetData?.historicAnnual ? <p>- Historic annual data</p> : ""}

                                                            </Table.Cell>
                                                            <Table.Cell>{String(dataSetData?.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.recordLevelValue) ? dsaContent.recordlevel : String(dataSetData?.aggregatedOrRecordLevelData) === String(dataSetLevelOptionTypes.aggregatedLevelValue) ? dsaContent.aggregatedlevel : ""}</Table.Cell>
                                                            <Table.Cell><a href="#" onClick={(e) => handleClickNavigation(routeName.datasetCheckYourAnswer, index, e)}>View</a></Table.Cell>
                                                            {
                                                                (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ||
                                                                    (checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Amendment)]) && isAssociatedProductYearsAvailable(dataSetData.historicAnnualDataList))) ? null :
                                                                    <Table.Cell><a href="#" onClick={(e) => handleClickNavigation(routeName.deleteDataset, index, e)}>Delete</a></Table.Cell>
                                                            }

                                                        </Table.Row>
                                                    )
                                                    )
                                                }
                                            </Table.Body>
                                        </Table>

                                    </div>
                                </div>
                                <div className="nhsuk-grid-row">
                                    <div className="nhsuk-u-padding-bottom-4"></div>
                                    {checkApplicationChangeType(String(dsaApplicationData.applicationChangeType), [String(applicationChangeType.Renewal)]) ? null: <p><a href="#" onClick={(e) => handleAnotherDataSet(e)}>Add another data set</a></p> }
                                    <div className="nhsuk-u-padding-bottom-7"></div>
                                    <Button name="continue" id="nhsuk-button-spinner" onClick={handleClick}>{dsaContent.btnTextContinue}</Button>

                                </div>
                            </>
                            : null}
                    </div>
                </main>
            </div>

        </>
    );
}
export default DataSetDetails;